import { Keyframes } from "@emotion/react";
import { Box, CircularProgress, IconButton, IconButtonProps, InputBase, InputBaseProps, keyframes, styled, StyledComponentProps, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import Iconify from "src/components/iconify";

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    padding: '8px',
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    maxWidth: 220,
    backgroundImage: `linear-gradient(
      rgba(255, 255, 255, 0.9), 
      rgba(255, 255, 255, 0.9)
    ), url("/assets/tooltip-overlay.png")`,
    backgroundSize: 'cover',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 5px 15px 5px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  },
}));


const StyledBox = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create(['border-color', 'box-shadow']),
  borderRadius: '4px',
  border: `1px solid ${theme.palette.grey[100]}`,
  padding: '15px',
  gap: '5px',
}));

const StyledInputBase = styled(InputBase)(() => ({
  padding: 0,
  overflow: 'auto',
}));

export enum StyledIconButtonAnimation {
  PULSE = 'pulse',
}

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const animationMap: Record<StyledIconButtonAnimation, Keyframes> = {
  [StyledIconButtonAnimation.PULSE]: pulseAnimation,
}

type StyledIconButtonProps = StyledComponentProps & {
  animation?: StyledIconButtonAnimation;
}

const StyledIconButton = styled(IconButton)<StyledIconButtonProps>(({ theme, color, animation }) => ({
  color: color ?? theme.palette.grey[500],
  padding: "2px",
  animation: animation ? `${animationMap[animation]} 2s infinite` : 'none',
  '&:disabled': {
    color: theme.palette.grey[500]
  },
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.grey[500],
}));

export type TextFieldWithIconTooltipProps = Omit<TooltipProps, 'title' | 'children'> & {
  title?: string,
  description?: string
};

interface TextFieldWithIconIconProps extends IconButtonProps {
  icon?: string;
  message?: string;
  animation?: StyledIconButtonAnimation;
  disabled?: boolean;
  loading?: boolean;
  tooltipProps?: TextFieldWithIconTooltipProps;
}

export interface TextFieldWithIconProps extends Omit<InputBaseProps, 'ref'> {
  iconProps?: TextFieldWithIconIconProps;
  onIconClick?: () => void;
}

const renderTooltipTitle = (
  title?: TextFieldWithIconTooltipProps['title'],
  description?: TextFieldWithIconTooltipProps['description']
) => (
  <Box display="flex" flexDirection="column" gap="5px">
    {title && <Typography variant="h6" fontWeight={700} color="text.primary">{title}</Typography>}
    {description && <Typography variant="body2" fontWeight={400} color="text.secondary">{description}</Typography>}
  </Box>
);

const renderIcon = (
  iconProps: TextFieldWithIconProps['iconProps'],
  onIconClick: TextFieldWithIconProps['onIconClick']
) => {
  if (!iconProps) return null;

  const { icon, loading, disabled, color, message, animation, tooltipProps } = iconProps;
  if (!icon && !loading && !message) return null;

  const iconButton = !!icon && (
    <StyledIconButton
      onClick={onIconClick}
      color={color}
      animation={animation}
      disabled={disabled}
    >
      <Iconify
        icon={icon}
        width={24}
      />
    </StyledIconButton>
  );

  const iconWithTooltip = !!tooltipProps && !!iconButton
    ? (
      <StyledTooltip
        title={renderTooltipTitle(tooltipProps.title, tooltipProps.description)}
        placement={tooltipProps.placement}
        arrow
      >
        {iconButton}
      </StyledTooltip>
    ) : iconButton;

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap="5px"
    >
      {message && (
        <Typography
          variant="body2"
          color="text.secondary"
          fontWeight="fontWeightMedium"
          fontSize="12px"
        >
          {message}
        </Typography>
      )}
      {loading && <StyledCircularProgress size={20} />}
      {iconWithTooltip}
    </Box>
  );
}

export default function TextFieldWithIcon(props: TextFieldWithIconProps) {
  const { value, onChange, onIconClick, iconProps, minRows = 8, maxRows = 10, placeholder, disabled, ...textFieldProps } = props;

  return (
    <StyledBox
      display="flex"
      flexDirection="column"
      alignItems="flex-end"
    >
      <StyledInputBase
        value={value}
        onChange={onChange}
        multiline
        minRows={minRows}
        maxRows={maxRows}
        fullWidth
        placeholder={placeholder}
        disabled={disabled}
        {...textFieldProps}
      />
      {renderIcon(iconProps, onIconClick)}
    </StyledBox>
  );
}
