import { useCallback, useEffect, useState } from 'react';
// @mui
import Collapse from '@mui/material/Collapse';
// routes
import { useActiveLink, usePathname } from 'src/hooks';
//
import { Divider, useTheme } from '@mui/material';
import { NavConfigProps, NavListProps } from '../types';
import NavItem from './nav-item';

// ----------------------------------------------------------------------

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
  config: NavConfigProps;
};

export default function NavList({ data, depth, hasChild, config }: NavListRootProps) {
  const pathname = usePathname();

  const active = useActiveLink(data.path, hasChild);

  const externalLink: boolean = data.path ? data.path.includes('http') : false;

  const [open, setOpen] = useState(active);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        externalLink={externalLink}
        onClick={handleToggle}
        config={config}
      />

      {hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList data={data.children} depth={depth} config={config} viewAllAction={data.viewAllAction}/>
        </Collapse>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
  config: NavConfigProps;
  viewAllAction?: VoidFunction;
};

function NavSubList({ data, depth, config, viewAllAction }: NavListSubProps) {
  const theme = useTheme();
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
      {/* Show view all button if there are 5 or more children */}
      {data.length >= 5 && viewAllAction && (
        <>
          <Divider sx={{ borderColor: theme.palette.info.lighter }} />
          <NavItem
            item={{
              title: 'View all',
              path: '',
            }}
            isViewAll
            active={false}
            depth={depth + 1}
            externalLink={false}
            config={config}
            onClick={viewAllAction}
            sx={{ mt: 0.5 }}
          />
         </>
      )}
    </>
  );
}
