import services, { PrivateDocumentDownloadResponse, PrivateDocumentRequest, Session, UserDocument, VendorDocument } from "@piccadilly-cloud/connect-platform-services";
import { v4 as uuidv4 } from 'uuid';


const uploadPublicFile = (session: Session, file: File, tags?: string[]): Promise<VendorDocument> =>
  services.edge.record.vendorDocument.getSignedUploadUrl({ filename: file.name, contentType: file.type, })({ token: session.token, vendor: session.appConfig.vendor, })
    .then((resp) =>
      services.edge.record.vendorDocument.uploadSignedDocumentToS3(resp.url, file)
        .then((uploadRes) => {
          const document: VendorDocument = {
            id: uuidv4(),
            vendor: session.appConfig.vendor,
            owner: session.account.email,
            createdAt: new Date(),
            updatedAt: new Date(),
            deleted: false,
            locationUrl: uploadRes.url,
            filename: file.name,
            fileSize: `${file.size}`,
            tags: tags || [],
          }
          return services.edge.record.vendorDocument.create(document)({ token: session.token, vendor: session.appConfig.vendor, })
        }));

const uploadPrivateFile = (session: Session, file: File, tags: string[], recordId?: string): Promise<UserDocument> =>
  services.edge.record.userDocument.getSignedUploadUrl({ filename: file.name, contentType: file.type, })({ token: session.token, vendor: session.appConfig.vendor, })
    .then((resp) =>
      services.edge.record.userDocument.uploadSignedDocumentToS3(resp.url, file)
        .then((uploadRes) => {
          const { url } = uploadRes;
          const splitUrl = url.split('?');
          let resultUrl = splitUrl[0];
          if (splitUrl.length > 2) {
            resultUrl = splitUrl.slice(0, splitUrl.length - 1).join();
          }
          const document: UserDocument = {
            id: uuidv4(),
            vendor: session.appConfig.vendor,
            owner: session.account.email,
            createdAt: new Date(),
            updatedAt: new Date(),
            deleted: false,
            locationUrl: resultUrl,
            filename: file.name,
            fileSize: `${file.size}`,
            userRecordId: recordId || '',
            isSigned: true,
            tags,
          }
          return services.edge.record.userDocument.create(document)({ token: session.token, vendor: session.appConfig.vendor, })
        }));

const getPrivateDownloadFilename = (url: string): string => decodeURIComponent(url.replace(process.env.REACT_APP_DOC_SUB_REPLACE || '', ''))

const downloadPrivateFile = (session: Session, url: string) => {
  const filename = getPrivateDownloadFilename(url);
  services.edge.record.userDocument.getSignedDownloadUrl({ filename })({ token: session.token, vendor: session.appConfig.vendor, })
    .then((signedUrl) => services.edge.record.userDocument.downloadSignedDocument(signedUrl.url)
      .catch((err) => {
        console.log('Download error!', err);
      }))
    .catch((err) => {
      console.log('Download error!', err);
    });
}

async function getAudioFileDuration(file: File): Promise<number> {
  const url = URL.createObjectURL(file);
  return new Promise((resolve) => {
    const audio = document.createElement("audio");
    audio.muted = true;
    const source = document.createElement("source");
    source.src = url; // --> blob URL
    audio.preload = "metadata";
    audio.appendChild(source);
    audio.onloadedmetadata = () => {
      resolve(audio.duration)
    };
  });
}

const uploadSignedPrivateDocument = (session: Session, file: File, request: PrivateDocumentRequest): Promise<PrivateDocumentDownloadResponse> =>
  services.edge.record.privateDocument.create(request)({ token: session.token })
    .then((res) => services.edge.record.privateDocument.putToProvider(res.uploadUrl, file)
      .then(() => services.edge.record.privateDocument.markUploaded(res.id)({ token: session.token, })));


const fileManager = {
  uploadPublicFile,
  uploadPrivateFile,
  downloadPrivateFile,
  getAudioFileDuration,
  uploadSignedPrivateDocument,
};

export default fileManager;
