import { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { useSnackbar } from 'notistack';
import { useSessionContext } from '../useSessionContext';
//

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized, account } = useSessionContext();
  const { enqueueSnackbar, } = useSnackbar();

  useEffect(() => {
    if (isAuthenticated) {
      const isAssessment = window.location.pathname && window.location.pathname.includes('/assessment/');
      const isRecruiterLike = account.accountRole === 'RECRUITER' || account.accountRole === 'WORKSPACE_ADMIN';
      if (isAssessment && isRecruiterLike) {
        enqueueSnackbar("Please sign out to view", { variant: 'info' });
      }
    }
  }, [account.accountRole, enqueueSnackbar, isAuthenticated])

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
}
