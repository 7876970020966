import { Badge, Box, ButtonBase, Divider, styled, useMediaQuery, Theme } from '@mui/material';
import AppAvatar from 'src/components/avatars/app-avatar';
// import FlexBox from 'components/flexbox/FlexBox';
import { H6, Small, Tiny } from 'src/components/typography';
// import useAuth from 'hooks/useAuth';
import { FC, useRef, useState } from 'react';
// import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRouter } from 'src/hooks';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { paths } from 'src/routes/paths';
import PopoverLayout from './popover-layout';

// styled components
const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  padding: 5,
  marginLeft: 4,
  borderRadius: 30,
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

const StyledSmall = styled(Small)(({ theme }) => ({
  display: 'block',
  cursor: 'pointer',
  padding: '5px 1rem',
  '&:hover': { backgroundColor: theme.palette.action.hover },
}));

const ProfilePopover: FC = () => {
  const session = useSessionContext();
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const router = useRouter();
  const [open, setOpen] = useState(false);
  const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const handleMenuItem = (path: string) => {
    navigate(path);
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await session.logout();
      setOpen(false);
      router.replace('/');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <StyledButtonBase disableRipple ref={anchorRef} onClick={() => setOpen(true)}>
        <Badge
          overlap="circular"
          variant="dot"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          sx={{
            alignItems: 'center',
            '& .MuiBadge-badge': {
              width: 11,
              height: 11,
              right: '4%',
              borderRadius: '50%',
              border: '2px solid #fff',
              backgroundColor: 'success.main',
            },
          }}
        >
          {upSm && (
            <Small mx={1} color="text.secondary">
              <Small fontWeight="600" display="inline">
                {session.account.personalInfo.firstName}{' '}{session.account.personalInfo.lastName}
              </Small>
            </Small>
          )}
          <AppAvatar
            src={session.account.personalInfo.avatarUrl ? session.account.personalInfo.avatarUrl : "/static/avatar/001-man.svg"}
            sx={{ width: 28, height: 28 }}
          />
        </Badge>
      </StyledButtonBase>

      <PopoverLayout
        hiddenViewButton
        maxWidth={320}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <Box display="flex" alignItems="center" gap={1}>
            <AppAvatar
              src={session.account.personalInfo.avatarUrl ? session.account.personalInfo.avatarUrl : "/static/avatar/001-man.svg"}
              sx={{ width: 35, height: 35 }}
            />
            <Box>
              <H6 sx={{ marginBlockStart: 0, marginBlockEnd: 0, }}>
                {session.account.personalInfo.firstName}{' '}{session.account.personalInfo.lastName}
              </H6>
              <Tiny sx={{ marginBlockStart: 0, marginBlockEnd: 0, }} display="block" fontWeight={500} color="text.disabled">
                {session.account.email}
              </Tiny>
            </Box>
          </Box>
        }
      >
        <Box pt={1}>
          {/* <StyledSmall onClick={() => handleMenuItem('/dashboard/profile')}>Set Status</StyledSmall> */}

          <StyledSmall onClick={() => handleMenuItem(paths.profile)}>
            Profile
          </StyledSmall>

          {/* <StyledSmall onClick={() => handleMenuItem('/dashboard/team-member')}>
            Manage Team
          </StyledSmall> */}

          <Divider sx={{ my: 1 }} />

          <StyledSmall
            onClick={() => {
              handleLogout();
              // toast.error('You Logout Successfully');
            }}
          >
            Sign out
          </StyledSmall>
        </Box>
      </PopoverLayout>
    </>
  );
};

export default ProfilePopover;
