export type TeqScoreRange = 1 | 2 | 3 | 4 | 5;
type TeqScoreBandObj<T> = { [K in TeqScoreRange]: T };

const rangeOptions: TeqScoreRange[] = [1, 2, 3, 4, 5];

interface TeqScorePalette {
  main: string;
  light: string;
  text: string;
};

const palette: TeqScoreBandObj<TeqScorePalette> = {
  1: { main: '#D9534F', light: '#FAEAEA', text: '#AE423F', },
  2: { main: '#F27F0C', light: '#FDF0E2', text: '#000000', },
  3: { main: '#FFC300', light: '#FFF8E0', text: '#000000', },
  4: { main: '#A2D317', light: '#F4FAE3', text: '#000000', },
  5: { main: '#3BAB65', light: '#EBF7F0', text: '#2F8951', },
}

const icons: TeqScoreBandObj<string> = {
  1: 'mdi:progress-close',
  2: 'mdi:arrow-bottom-left-thin-circle-outline',
  3: 'mdi:do-not-enter-outline',
  4: 'mdi:arrow-top-right-thin-circle-outline',
  5: 'mdi:star-circle',
}

const scoreRot: TeqScoreBandObj<string> = {
  1: '-117deg',
  2: '-70deg',
  3: '-23deg',
  4: '24deg',
  5: '70deg',
};

const dialRot: TeqScoreBandObj<string> = {
  1: '-96deg',
  2: '-48deg',
  3: '0deg',
  4: '48deg',
  5: '96deg',
}

const bandToFitLabel = (band: TeqScoreRange): string => {
  switch (band) {
    case 5:
      return 'Best fit';
    case 4:
      return 'Good fit';
    case 3:
      return 'Average fit';
    case 2:
      return 'Weak fit';
    case 1:
    default:
      return 'No fit';
  }
};

const getFixedBand = (band: number): TeqScoreRange => {
  if (band > 5) {
    return 5;
  } if (band < 1) {
    return 1;
  }

  return Math.round(band) as TeqScoreRange;
}

const teqScoreConfigV2 = {
  palette,
  icons,
  scoreRot,
  dialRot,
  bandToFitLabel,
  getFixedBand,
  rangeOptions,
};

export default teqScoreConfigV2;
