// @mui
import Box, { BoxProps } from '@mui/material/Box';
// hooks
import { useResponsive } from 'src/hooks';
// components
//
import { useTheme } from '@mui/material';
import { HEADER } from '../config-layout';

// ----------------------------------------------------------------------

// const SPACING = 8;

export default function Main({ children, sx, ...other }: BoxProps) {
  const theme = useTheme();
  const lgUp = useResponsive('up', 'lg');
  const xsDown = useResponsive('down', 'xs')

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: theme.palette.background.paper,
        minHeight: 1,
        display: 'flex',
        flexDirection: 'column',
        pt: `${HEADER.H_MOBILE}px`,
        ...(lgUp && {
          pt: `${HEADER.H_MOBILE}px`,
          pb: 5,
        }),
      }}
    >
      <Box sx={{ px: xsDown ? 0 : 'unset', }} >
        {children}
      </Box>
    </Box>
  );
}
