import teqScoreConfigV2 from 'src/components-piccadilly/teq-score/v2/config';

import { Paper } from '@mui/material';

interface HeaderBannerProps {
  children: React.ReactNode;
}

export default function HeaderBanner(props: HeaderBannerProps) {
  const { children } = props;
  return (
    <Paper
      elevation={3}
      sx={{
        backgroundColor: teqScoreConfigV2.palette[2].light,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '200px',
      }}
    >
      {children}
    </Paper>
  );
}
