import { AccountRole, UserAccount } from "@piccadilly-cloud/connect-platform-services";

const getRole = (role: AccountRole): string => role
  .split('_')
  .map((str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase())
  .join(' ')


const isCurrentUserRecruiter = (role: AccountRole): boolean => {
  switch (role) {
    case 'ADMIN':
    case 'RECRUITER':
    case 'WORKSPACE_ADMIN':
      return true;
    default:
      return false;
  }
}

const phoneRegExp = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/;
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const normalizePhoneNumber = (number: string): string => {
  const newNumber = number
    .replaceAll(' ', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('-', '')
    .replaceAll('_', '');
  return `+1${newNumber}`;
}

const parsePhoneNumber = (number: string): string => {
  const replacedInternational = number.replaceAll('+1', '');
  if (replacedInternational.length !== 10) {
    return replacedInternational;
  }
  return `(${replacedInternational.slice(0, 3)}) ${replacedInternational.slice(3, 6)}-${replacedInternational.slice(6)}`;
}

const getInitialsFromAccount = (userAccount: UserAccount): string =>
  getInitials(userAccount.personalInfo.firstName, userAccount.personalInfo.lastName)

const getInitials = (firstName: string, lastName: string): string => {
  let initials = ''
  if (firstName.length !== 0) {
    initials = firstName[0];
  }
  if (lastName.length !== 0) {
    initials = `${initials}${lastName[0]}`;
  }
  return initials;
}

const userAccount = {
  getRole,
  isCurrentUserRecruiter,
  phoneRegExp,
  emailRegex,
  normalizePhoneNumber,
  parsePhoneNumber,
  getInitialsFromAccount,
  getInitials,
}

export default userAccount;
