import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
import UnauthenticatedLayout from 'src/layouts/unauthenticated';

// components
import SessionGuard from 'src/contexts/session/guards/session-guard';
import GuestGuard from 'src/contexts/session/guards/guest-guard';
import AuthBasicLayout from 'src/layouts/auth/basic';


const Root = lazy(() => import('src/pages/root'));
const ForgotPasswordPage = lazy(() => import('src/pages/auth/forgot-password-universal'));
const Page404 = lazy(() => import('src/pages/404'));
const Page403 = lazy(() => import('src/pages/403'));
const RegisterGettingStarted = lazy(() => import('src/pages/auth/register-get-started'));
const RegisterGettingStartedInvited = lazy(() => import('src/pages/auth/register-get-started-invite'));

const PrivacyPolicyPage = lazy(() => import('src/pages/app/privacy-policy-page'));
const TermsOfServicePage = lazy(() => import('src/pages/app/terms-of-service-page'));

const core = {
  element: (
    <SessionGuard>
      <AuthClassicLayout>
        <Outlet />
      </AuthClassicLayout>
    </SessionGuard>
  ),
  children: [
    { path: '', element: <Root /> },
    { path: '/login', element: <Root /> },
    { path: '/forgot-password', element: (<ForgotPasswordPage />), },
    { path: '404', element: <Page404 /> },
    { path: '403', element: <Page403 /> },
  ],
};

const gettingStarted = {
  element: (
    <SessionGuard>
      <GuestGuard>
        <AuthBasicLayout>
          <Outlet />
        </AuthBasicLayout>
      </GuestGuard>
    </SessionGuard>
  ),
  children: [
    {
      path: 'getting-started',
      element: (<RegisterGettingStarted />),
    },
    {
      path: 'getting-started/access-code',
      element: (<RegisterGettingStarted />),
    },
    {
      path: 'getting-started/invited',
      element: (<RegisterGettingStartedInvited />),
    },
  ],
};

const terms = {
  element: (
    <SessionGuard>
      <GuestGuard>
        <UnauthenticatedLayout>
          <Outlet />
        </UnauthenticatedLayout>
      </GuestGuard>
    </SessionGuard>
  ),
  children: [
    {
      path: 'privacy-policy',
      element: (<PrivacyPolicyPage />),
    },
    {
      path: 'terms-and-conditions',
      element: (<TermsOfServicePage />),
    },
  ],
};

export const universalLoginRoutes = [
  core,
  gettingStarted,
  terms,
]