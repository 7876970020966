const navigatorErrorMessage = 'Could not find `userAgent` or `userAgentData` window.navigator properties to set `os`, `browser` and `version`';
const removeExcessMozillaAndVersion = /^mozilla\/\d\.\d\W/;
const browserPattern = /(\w+)\/(\d+\.\d+(?:\.\d+)?(?:\.\d+)?)/g;
const engineAndVersionPattern = /^(ver|cri|gec)/;
const brandList = ['chrome', 'opera', 'safari', 'edge', 'firefox'];
const unknown = 'Unknown';
const empty = '';

interface UserAgentData {
  brands: Array<{ brand: string; version: string }>;
  platform: string;
}

interface DetectionResult {
  os: string;
  browser: string;
  version: string;
}

const mobiles: Record<string, RegExp> = {
  iphone: /iphone/,
  ipad: /ipad|macintosh/,
  android: /android/,
};

const desktops: Record<string, RegExp> = {
  windows: /win/,
  mac: /macintosh/,
  linux: /linux/,
};

const detectPlatform = (
  customUserAgent?: string,
  customUserAgentData?: UserAgentData
): DetectionResult => {
  let { userAgentData } = (navigator as any);
  let { userAgent } = navigator;

  // Use a provided UA string instead of the browser's UA
  userAgent = typeof customUserAgent === 'string' ? customUserAgent : userAgent;

  // Use a provided UA data string instead of the browser's UA data
  userAgentData = customUserAgentData || userAgentData;

  if (userAgent) {
    const ua = userAgent.toLowerCase().replace(removeExcessMozillaAndVersion, empty);

    // Determine the operating system.
    const mobileOS = Object.keys(mobiles).find(
      (os) => mobiles[os].test(ua) && navigator.maxTouchPoints >= 1
    );
    const desktopOS = Object.keys(desktops).find((os) => desktops[os].test(ua));
    const os = mobileOS || desktopOS || unknown;

    // Extract browser and version information.
    const browserTest = ua.match(browserPattern);
    const versionRegex = /version\/(\d+(\.\d+)*)/;
    const safariVersion = ua.match(versionRegex);
    const saVersion = safariVersion ? safariVersion[1] : null;
    const browserOffset =
      browserTest && browserTest.length > 2 && !engineAndVersionPattern.test(browserTest[1])
        ? 1
        : 0;
    const browserResult = browserTest
      ? browserTest[browserTest.length - 1 - (browserOffset || 0)].split('/')
      : [unknown, unknown];
    const browser = browserResult[0] || unknown;
    const version = saVersion || browserResult[1] || unknown;

    return { os, browser, version };
  } if (userAgentData) {
    const os = userAgentData.platform.toLowerCase();
    let platformData: { browser: string; version: string } | undefined;

    // Extract platform brand and version information.
    // eslint-disable-next-line no-restricted-syntax
    for (const agentBrand of userAgentData.brands) {
      const agentBrandEntry = agentBrand.brand.toLowerCase();
      const foundBrand = brandList.find((brand) => agentBrandEntry.includes(brand));
      if (foundBrand) {
        platformData = { browser: foundBrand, version: agentBrand.version };
        break;
      }
    }
    const brandVersionData = platformData || { browser: unknown, version: unknown };
    return { os, ...brandVersionData };
  } 
    // Log error message if there's a problem.
    console.error(navigatorErrorMessage);

    return {
      os: navigator.platform || unknown,
      browser: 'chrome',
      version: unknown,
    };
  
};

function isSafari() {
  return detectPlatform().browser === 'safari';
}

function isChrome() {
  return detectPlatform().browser === 'chrome';
}

function isFirefox() {
  return detectPlatform().browser === 'firefox';
}

function isEdge() {
  return detectPlatform().browser === 'edge';
}

function isOpera() {
  return detectPlatform().browser === 'opera';
}


const pdfUtil = {
  detectPlatform,
  isSafari,
  isChrome,
  isFirefox,
  isEdge,
  isOpera
};

export default pdfUtil;