// @mui
import Box from '@mui/material/Box';
// auth
// routes
// hooks
// theme
// components
import { useResponsive } from 'src/hooks';
import Footer from '../nonworkspace/footer';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function PrintClassicLayout({ children, image, title }: Props) {
  const isDesktop = useResponsive('up', 'md');

  return (
    <>
      <Box
        sx={{
          paddingTop: isDesktop ? '5vh' : '2vh',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box display="block" sx={{ width: '100%', maxWidth: '1000px' }}>
          {children}
        </Box>
      </Box>

      <Footer />
    </>
  );
}
