import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useSessionContext } from "src/contexts/session/useSessionContext";
import ctrl from "src/ctrl";
import { createClient, ListenLiveClient, LiveTranscriptionEvent, LiveTranscriptionEvents, SOCKET_STATES } from "@deepgram/sdk";
import piccIcon from "src/utils/config/icon";
import { StyledIconButtonAnimation, TextFieldWithIconProps, TextFieldWithIconTooltipProps } from "../views/candidate/application/assessment/response-types/text-field-with-icon";
import { useLoading } from "./use-loading";

const DEFAULT_TOOLTIP_PROPS: TextFieldWithIconTooltipProps = {
  title: 'Live transcription',
  description: 'Use the speech-to-text feature that will transcribe as you speak your answer',
  placement: 'right-end',
};

/**
 * Returns a transcriber object that can be used to transcribe audio.
 */
export const useTranscriber = (text: string, updateText: Dispatch<SetStateAction<string>>) => {
  const session = useSessionContext();

  const keepAlive = useRef<NodeJS.Timeout | null>(null);
  const audioContext = useRef<AudioContext | null>(null);
  const client = useRef<ListenLiveClient | null>(null);

  const transcribing = useLoading();
  const [transcription, setTranscription] = useState<string>('');
  const [partialTranscription, setPartialTranscription] = useState<string>('');
  const [iconProps, setIconProps] = useState<TextFieldWithIconProps['iconProps']>({
    icon: piccIcon.transcription.microphone,
    tooltipProps: DEFAULT_TOOLTIP_PROPS,
  });

  const maybeSetBlocked = async () => {
    if ((await navigator.permissions.query({ name: 'microphone' as PermissionName })).state === 'denied') {
      setIconProps({
        icon: piccIcon.transcription.block,
        disabled: true,
      });
    }
  };

  useEffect(() => {
    maybeSetBlocked();
    return () => {
      if (client.current) client.current.requestClose();
      if (audioContext.current) audioContext.current.close();
    };
  }, []);

  const reset = () => {
    if (keepAlive.current) clearInterval(keepAlive.current);
    audioContext.current = null;
    client.current = null;

    transcribing.stop();
    setTranscription('');
    setPartialTranscription('');
    setIconProps({
      icon: piccIcon.transcription.microphone,
      tooltipProps: DEFAULT_TOOLTIP_PROPS,
    });
  };

  const initClient = useCallback(async () => {
    setIconProps({ message: 'Initializing', loading: true });
    const apiKey = await ctrl.transcription.getAPIKey(session);
    const dgClient = createClient(apiKey);
    const listenLiveClient = dgClient.listen.live({
      model: "nova-2",
      language: "en-US",
      channels: 1,
      encoding: "linear16",
      smart_format: true,
      sample_rate: 16000,
      interim_results: true,
      endpointing: 300
    });

    if (keepAlive.current) clearInterval(keepAlive.current);
    keepAlive.current = setInterval(() => {
      if (listenLiveClient.getReadyState() !== SOCKET_STATES.open) return;
      listenLiveClient.keepAlive();
    }, 10 * 1000);

    listenLiveClient.on(LiveTranscriptionEvents.Open, () => {
      console.info('Transcriber: session opened');

      listenLiveClient.addListener(LiveTranscriptionEvents.Close, async () => {
        console.info('Transcriber: session closed');
        if (keepAlive.current) clearInterval(keepAlive.current);
      });

      listenLiveClient.addListener(LiveTranscriptionEvents.Error, async (error) => {
        console.error('Transcriber: error received', error);
      });

      listenLiveClient.on(LiveTranscriptionEvents.Transcript, (transcript: LiveTranscriptionEvent) => {
        if (transcript.is_final) {
          setPartialTranscription('');
          setTranscription((previousTranscription) => `${previousTranscription} ${transcript.channel.alternatives[0].transcript}`);
        } else {
          setPartialTranscription(transcript.channel.alternatives[0].transcript);
        }
      });
    });

    client.current = listenLiveClient;
    setIconProps({ icon: piccIcon.transcription.microphone });
  }, [session]);

  const initAudioProcessor = async () => {
    if (!client.current) throw new Error('no client initialized');

    setIconProps({ message: 'Waiting for access', loading: true });
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    audioContext.current = new AudioContext({ sampleRate: 16000 });
    const source = audioContext.current.createMediaStreamSource(stream);

    await audioContext.current.audioWorklet.addModule('/worklets/processor.js');
    const processorNode = new AudioWorkletNode(audioContext.current, 'processor');

    processorNode.port.onmessage = (messageEvent: MessageEvent<ArrayBuffer>) => {
      if (!client.current) return;

      const audioData = messageEvent.data;
      client.current.send(audioData);
    };

    source.connect(processorNode).connect(audioContext.current.destination);
    setIconProps({ icon: piccIcon.transcription.microphone });
  };

  useEffect(() => {
    const draftTranscription = partialTranscription ? ` ${partialTranscription}` : '';
    updateText(`${transcription}${draftTranscription}`);
  }, [transcription, partialTranscription, updateText]);

  const start = async () => {
    if (!client.current) {
      await initClient();
    }

    try {
      if (!audioContext.current) {
        await initAudioProcessor();
      } else {
        await audioContext.current.resume();
      }

      transcribing.start();
      setTranscription(text);
      setIconProps({
        icon: piccIcon.transcription.stop,
        color: 'error',
        message: 'Stop transcribing',
        animation: StyledIconButtonAnimation.PULSE,
      });
    } catch (error) {
      reset();
      await maybeSetBlocked();
      throw error;
    }
  };

  const stop = async () => {
    if (!audioContext.current) throw new Error('audio context not initialized');

    await audioContext.current.suspend();
    transcribing.stop();
    setIconProps({
      icon: piccIcon.transcription.microphone,
      tooltipProps: DEFAULT_TOOLTIP_PROPS,
    });
  }

  return {
    iconProps,
    isTranscribing: transcribing.isLoading,
    setPartialTranscription,
    setTranscription,
    start,
    stop,
  };
};
