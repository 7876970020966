
import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { LoadingScreen } from 'src/components/loading-screen';
import AuthGuard from 'src/contexts/session/guards/auth-guard';
import CandidateGuard from 'src/contexts/session/guards/candidate-guard';
import DashboardLayout from 'src/layouts/nonworkspace/layout';

const Dashboard = lazy(() => import('src/pages/dashboard'));
const Profile = lazy(() => import('src/pages/user-account/profile'));
const CandidateApplicationWizardPage = lazy(() => import('src/pages/candidate/application/application-wizard'))


export const candidateRoutes = [
  { 
    path: '',
    element: (
      <AuthGuard>
        <CandidateGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </CandidateGuard>
      </AuthGuard>
    ),
    children: [
      { path: 'dashboard', element: <Dashboard />, index: true, },
      { path: 'profile', element: <Profile />, index: true, },

      { path: 'candidate/application/:applicationId', element: <CandidateApplicationWizardPage /> },
    ]
  }
];

