import { Button, Typography } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import services, { TEQApplicationStatus } from '@piccadilly-cloud/connect-platform-services';
import { LIMIT_TEN, formatNumberToWord } from '@piccadilly-cloud/utils';
import { useEffect, useState } from 'react';
import teqScoreConfigV2 from 'src/components-piccadilly/teq-score/v2/config';
import { useSettingsContext } from 'src/components/settings';
import { useSessionContext } from 'src/contexts/session/useSessionContext';
import { useDynamicVendor, useResponsive } from 'src/hooks';
import { HEADER, NAV } from '../config-layout';
import HeaderBanner from './header-banner';
import RequestCreditsDialog from './request-credits-dialog';

const SPACING = 8;

export default function Main({ children, sx, ...other }: BoxProps) {
  const settings = useSettingsContext();
  const session = useSessionContext();
  const lgUp = useResponsive('up', 'lg');
  const [showBanner, setShowBanner] = useState(false);
  const { dynamicVendor, isEnterpriseVendor } = useDynamicVendor();
  const [unprocessedApplicationsCount, setUnprocessedApplicationsCount] = useState(0);
  const [isRequestCreditsDialogOpen, setIsRequestCreditsDialogOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const res = await services.edge.teq.applications.getApplicationsCount(TEQApplicationStatus.SUBMITTED)({ token: session.token });
        setUnprocessedApplicationsCount(res);
      } catch (error) {
        console.error(error);
      }
    })()
  }, [session.token])

  useEffect(() => {
    if (!dynamicVendor || !isEnterpriseVendor) return;

    const hasRemainingAssessments = dynamicVendor.assessments.remaining > 0;
    if (hasRemainingAssessments) return;

    setShowBanner(true);
  }, [isEnterpriseVendor, dynamicVendor, session.account.accountRole])

  const isNavHorizontal = settings.themeLayout === 'horizontal';
  const isNavMini = settings.themeLayout === 'mini';

  const renderBanner = () => {
    if (!session.appConfig.behavior.recruiterBehavior.prequalifyCandidatesEnabled) return null;
    if (!showBanner) return null;

    const getUnprocessedApplicationsCount = () =>
      formatNumberToWord(unprocessedApplicationsCount, LIMIT_TEN);

    const renderSubtitle = () =>
      !!unprocessedApplicationsCount && (
        <Typography variant="body2" color="#914C07" fontWeight={400}>
          You have {getUnprocessedApplicationsCount()} candidates on hold that might be a good fit.
        </Typography>
      )

    return (
      <HeaderBanner>
        <Box display="flex" flexDirection="column" alignItems="center" rowGap={1}>
          <Box component="img" src="/assets/credit-warn.png" alt="assessment limit" width={24} height={24} />
          <Typography variant="body2" color="#914C07" fontWeight={700}>
            You have reached your assessment limit
          </Typography>
          {renderSubtitle()}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsRequestCreditsDialogOpen(true)}
            sx={{
              backgroundColor: teqScoreConfigV2.palette[2].main,
            }}
          >
            Request credits
          </Button>
        </Box>
      </HeaderBanner>
    )
  };

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_MOBILE + 24}px`,
          pb: 10,
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 15,
          }),
        }}
      >
        {renderBanner()}
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          // px: 2,
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        mt: session.appConfig.behavior.useDemoMode ? '54px' : 'unset',
        ...sx,
      }}
      {...other}
    >
      <Box sx={{ pb: 2 }}>
        {renderBanner()}
      </Box>
      {children}
      <RequestCreditsDialog
        open={isRequestCreditsDialogOpen}
        onClose={() => setIsRequestCreditsDialogOpen(false)}
      />
    </Box>
  );
}
