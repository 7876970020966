import { Dialog, DialogContent, DialogTitle, IconButton, Typography, useTheme } from "@mui/material";
import Iconify from "src/components/iconify/iconify";
import piccIcon from "src/utils/config/icon";

export interface RequestCreditsDialogProps {
  open: boolean;
  onClose: VoidFunction;
}

export default function RequestCreditsDialog(props: RequestCreditsDialogProps) {
  const { open, onClose, } = props;
  const theme = useTheme();
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3 }}
      >
        <Typography
          variant='h5'
          color={theme.palette.grey[700]}
        >
          Request more credits
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
        >
          <Iconify icon={piccIcon.actions.close} />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Typography
          variant='body1'
          fontWeight={400}
          color={theme.palette.grey[700]}
        >
          Please reach out to your Client Success Manager to purchase more credits.
        </Typography>
      </DialogContent>
    </Dialog>
  )
}