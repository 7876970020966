import { Box, Button, Typography, useTheme } from "@mui/material";
import Iconify from "src/components/iconify";
import { useSessionContext } from "src/contexts/session/useSessionContext";

export default function GlobalBanner() {
  const theme = useTheme();

  const session = useSessionContext();

  if (!session.appConfig.behavior.useDemoMode) {
    return undefined;
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '54px',
        top: 0,
        backgroundColor: theme.palette.primary.darker,
        position: 'fixed',
        zIndex: 1299,
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={{ height: '100%', }}>
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" columnGap={1}>
          <Iconify icon="mdi:eye" color={theme.palette.background.paper} />
          <Typography variant="h6" color={theme.palette.background.paper}>
            Your preview expires soon.
          </Typography>
          <Button
            color="success"
            variant="contained"
            size="small"
            onClick={() => { alert('todo') }}
          >
            Upgrade
          </Button>
        </Box>
      </Box>
    </Box>
  )
}